import React from 'react';
import classNames from 'classnames';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useDispatchAutoLogin } from '../../../Redux/Actions/AuthActions';
import { useTranslation } from 'react-i18next';
import LanguageSelectButton from '../LanguageSelectButton';
import { STORAGE_SELECTED_LANGUAGE } from '../../../Library/Types/Constants';
import moment from 'moment/moment';
import { getSelectedLanguage, momentConfiguration } from '../../../Library/Functions/LocalizationFunctions';
import i18n from 'i18next';
import Item from './Item';
import { useNavigate } from 'react-router';

const logo = require('../../../Assets/Images/sotologo_white.png');

interface Props {
  showPrice: boolean;
  showStartPage: boolean;
  showRegister: boolean;
  showLogin: boolean;
}

/**
 * Responsive()
 * @constructor
 */
export default function Responsive(props: Props) {
  const { showPrice, showStartPage, showRegister, showLogin } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [sticky, setSticky] = React.useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>('es-ES');

  const setLanguage = React.useCallback((lng: string) => {
    // Change language
    localStorage.setItem(STORAGE_SELECTED_LANGUAGE, lng);
    setSelectedLanguage(lng);
    moment().locale(getSelectedLanguage(false));
    moment.localeData(getSelectedLanguage(false)).set(momentConfiguration(getSelectedLanguage(true)));
    i18n.changeLanguage(getSelectedLanguage(true)).then(() => {});
  }, []);

  React.useEffect(() => {
    setSelectedLanguage(getSelectedLanguage(true));
  }, []);

  const dispatchAuthAuto = useDispatchAutoLogin();

  const handleScrollEvent = React.useCallback(() => {
    if (window.scrollY > 50) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }, []);

  const handleAuthStateChange = React.useCallback(
    (state: any) => {
      if (state && !loading && !isLoggedIn) {
        setLoading(true);
        dispatchAuthAuto(state.uid)
          .then(() => {
            navigate('/dashboard');
            setIsLoggedIn(true);
          })
          .finally(() => setLoading(false));
      }
    },
    [dispatchAuthAuto, isLoggedIn, loading, navigate],
  );

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), handleAuthStateChange);
    return () => unsubscribe();
  }, [handleAuthStateChange]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [handleScrollEvent]);

  const renderLogin = () => {
    if (showLogin) {
      if (isLoggedIn) {
        return (
          <Item
            caption={t('startPage')}
            navigateTo={'/dashboard'}
            visible={true}
            icon={'fas fa-home'}
            containerClass={'bg-purple-900'}
          />
        );
      }
      return (
        <Item
          caption={t('modules.website.login')}
          navigateTo={'/login'}
          visible={true}
          icon={'fas fa-sign-in'}
          containerClass={'bg-purple-900'}
        />
      );
    }
    return null;
  };

  const renderContent = () => {
    return (
      <>
        <div className={classNames('display-none', 'lg:flex lg:w-1/6')} />

        <div className={classNames('flex flex-1 flex-col items-center', 'lg:flex lg:w-4/6 lg:flex-row')}>
          <div className={classNames('hidden items-center', 'lg:flex lg:flex-1')}>
            <Item visible={true} navigateTo={'/'}>
              <div className={'flex flex-row items-center'}>
                <img src={logo} alt={'Sotosoft Logo'} className={'h-10 mr-4'} />
                <div className={classNames('text-3xl font-bold text-white')}>SotoDocs</div>
              </div>
            </Item>
          </div>

          <div className={classNames('flex')}>
            <Item visible={showStartPage} caption={t('startPage')} navigateTo={'/'} />
            <Item visible={showPrice} caption={t('modules.website.prices')} navigateTo={'/#price'} />
            <Item visible={true} caption={t('modules.website.contact')} navigateTo={'/contact'} />
            <Item visible={showRegister} caption={t('register')} navigateTo={'/register'} />
            {renderLogin()}
            <LanguageSelectButton selected={selectedLanguage} onChange={setLanguage} />
          </div>
        </div>

        <div className={classNames('display-none', 'lg:flex lg:w-1/6')} />
      </>
    );
  };

  const fixedNavbar = () => {
    return (
      <div
        className={classNames('hidden flex-col items-center p-3 shadow-2xl', 'bg-purple-700', 'lg:flex-row lg:flex')}
      >
        {renderContent()}
      </div>
    );
  };

  const stickyNavbar = () => {
    return (
      <div
        className={classNames(
          'fixed flex-1 hidden left-0 right-0 flex-col items-center p-3 shadow-2xl z-40',
          'bg-purple-700',
          'lg:flex-row lg:flex',
          { 'top-0 transition-all ease-in duration-1000': sticky },
          { '-top-96': !sticky },
        )}
      >
        {renderContent()}
      </div>
    );
  };

  return (
    <>
      {fixedNavbar()}
      {stickyNavbar()}
    </>
  );
}
