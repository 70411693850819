import React from 'react';
import { Client } from '../../../Library/Types/ClientTypes';
import InputRow from '../../Forms/InputRow';
import GroupFilter from './GroupFilter';
import { useTranslation } from 'react-i18next';

interface Props {
  clients: Client[];
  onChange: (clients: Client[]) => void;
}

/**
 * Filter()
 * @param props
 * @constructor
 */
export default function Filter(props: Props) {
  const { clients, onChange } = props;
  const { t } = useTranslation();
  const [selectedGroup, setSelectedGroup] = React.useState<number>(0);
  const [searchValue, setSearchValue] = React.useState<string>('');

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if ((searchValue && searchValue.length >= 3) || selectedGroup > 0) {
      setTimeout(() => {
        onChange(
          clients.filter(
            (item) =>
              (searchValue && searchValue.length >= 3
                ? item.firstName.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
                  item.lastName.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
                  item.taxNumber.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
                  item.internalId.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
                  item.mail.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
                  item.companyName.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
                : true) && (selectedGroup > 0 ? item.groupId === selectedGroup : true),
          ),
        );
      }, 500);
    } else {
      onChange(clients);
    }

    return () => clearTimeout(timer);
  }, [clients, onChange, searchValue, selectedGroup]);

  return (
    <div className={'flex flex-1 flex-row'}>
      <div className={'flex-grow'}>
        <InputRow
          autoFocus
          onChange={setSearchValue}
          value={searchValue}
          placeholder={t('searchMandant')}
          showClear={true}
        />
      </div>
      <GroupFilter onChange={setSelectedGroup} selected={selectedGroup} />
    </div>
  );
}
