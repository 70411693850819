import React from 'react';
import { DocumentState, DocumentType } from '../../../Library/Types/DocumentTypes';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import Button from './Button';
import { useDispatchDocumentSetBooked, useDispatchDocumentUpdate } from '../../../Redux/Actions/DocumentActions';
import ConfirmModal from '../../Modals/ConfirmModal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useLoadingModal } from '../../../Library/Hooks/GlobalHooks';

interface Props {
  document: DocumentType;
}

/**
 * ChangeStateButtons()
 * @param props
 * @constructor
 */
export default function ChangeStateButtons(props: Props) {
  const { document } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);
  const { currentDocumentBookingTime } = useAppSelector((state) => state.document);
  const { setLoading, loadingModal, setHeadline } = useLoadingModal();
  const [askBooked, setAskBooked] = React.useState<boolean>(false);
  const [selectedState, setSelectedState] = React.useState<DocumentState | null>(null);

  const dispatchUpdateDocument = useDispatchDocumentUpdate();
  const dispatchSetBooked = useDispatchDocumentSetBooked();

  const updateState = React.useCallback(
    (state: DocumentState) => {
      if (state) {
        setSelectedState(null);
        setLoading(true);
        setHeadline(t('modules.documents.updatingState'));

        const mergedDocument: DocumentType = {
          ...document,
          state,
          bookingTime: document.bookingTime + currentDocumentBookingTime,
        };

        dispatchUpdateDocument(mergedDocument)
          .then(() => {
            navigate(-1);
          })
          .finally(() => setLoading(false));
      }
    },
    [currentDocumentBookingTime, dispatchUpdateDocument, document, navigate, setHeadline, setLoading, t],
  );

  const handleSetBooked = React.useCallback(() => {
    if (document) {
      setAskBooked(false);
      setLoading(true);
      setHeadline(t('modules.documents.setBooked'));

      dispatchSetBooked({ ...document, bookingTime: document.bookingTime + currentDocumentBookingTime })
        .then(() => {
          navigate(-1);
        })
        .finally(() => setLoading(false));
    }
  }, [currentDocumentBookingTime, dispatchSetBooked, document, navigate, setHeadline, setLoading, t]);

  if (document.state !== DocumentState.done && user.isCustomerUser) {
    return (
      <>
        <div className={'flex flex-1 flex-row -m-2'}>
          <Button
            state={DocumentState.open}
            onPress={setSelectedState}
            visible={document.state !== DocumentState.open}
          />
          <Button
            state={DocumentState.question}
            onPress={setSelectedState}
            visible={document.state !== DocumentState.question}
          />
          <Button
            state={DocumentState.unreadable}
            onPress={setSelectedState}
            visible={document.state !== DocumentState.unreadable}
          />
          <Button
            state={DocumentState.notBookable}
            onPress={setSelectedState}
            visible={document.state !== DocumentState.notBookable}
          />
          <Button
            state={DocumentState.notDeductible}
            onPress={setSelectedState}
            visible={document.state !== DocumentState.notDeductible}
          />
          <Button state={DocumentState.done} onPress={() => setAskBooked(true)} />
        </div>

        <ConfirmModal
          headline={t('alert.markBooked.headline')}
          text={t('alert.markBooked.text', { name: document.originalName })}
          visible={askBooked}
          onClose={() => setAskBooked(false)}
          onConfirm={handleSetBooked}
          type={'success'}
          icon={'fas fa-check-circle'}
          confirmText={t('bookDocument')}
        />

        <ConfirmModal
          headline={t('alert.askChangeState.headline')}
          text={t('alert.askChangeState.text', {
            state: selectedState ? t(`modules.documents.statusText.${selectedState}`) : '',
          })}
          visible={!!selectedState}
          onClose={() => setSelectedState(null)}
          onConfirm={() => updateState(selectedState as DocumentState)}
          type={'primary'}
          icon={'fas fa-question-circle'}
        />

        {loadingModal}
      </>
    );
  }
  return null;
}
