import { DocumentType, InvoiceType } from '../../Library/Types/DocumentTypes';
import moment from 'moment/moment';
import { isValidDate, splitFileToNameAndExtension } from '../../Library/Functions/HelperFunctions';

export type DocumentUpdateState = {
  data: {
    bankName: string;
    bankIban: string;
    bankStartDate: string;
    bankEndDate: string;
    invoiceNumber: string;
    invoiceDate: string;
    type: InvoiceType;
    creditor: string;
    debitor: string;
    originalName: string;
    additionalInformation: string;
  };
  fileName: string; // Helpers to show only file name
  fileExtension: string; // Helper to show file extension
  initialized: boolean;
  hasChanges: boolean;
};

export type DocumentUpdateActions =
  | { type: 'clear' }
  | { type: 'init'; payload: DocumentType }
  | { type: 'updateInvoiceData'; payload: DocumentType }
  | { type: 'setBank'; payload: { name: string; iban: string } }
  | { type: 'bankName'; payload: string }
  | { type: 'bankIban'; payload: string }
  | { type: 'bankStartDate'; payload: string }
  | { type: 'bankEndDate'; payload: string }
  | { type: 'invoiceNumber'; payload: string }
  | { type: 'invoiceDate'; payload: string }
  | { type: 'additionalInformation'; payload: string }
  | { type: 'type'; payload: InvoiceType }
  | { type: 'originalName'; payload: string }
  | { type: 'resetHasChanges' }
  | { type: 'creditor'; payload: string }
  | { type: 'debitor'; payload: string };

export const documentUpdateInitialState: DocumentUpdateState = {
  data: {
    bankName: '',
    bankIban: '',
    bankStartDate: '',
    bankEndDate: '',
    invoiceNumber: '',
    invoiceDate: moment().format('YYYY-MM-DD'),
    type: InvoiceType.unknown,
    creditor: '',
    debitor: '',
    originalName: '',
    additionalInformation: '',
  },
  fileName: '',
  fileExtension: '',
  hasChanges: false,
  initialized: false,
};

const checkDocumentType = (fileType: string): InvoiceType => {
  if (
    fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    fileType === 'application/vnd.ms-excel' ||
    fileType === 'text/csv'
  ) {
    return InvoiceType.bank;
  }
  return InvoiceType.incoming;
};

export const documentUpdateReducer = (
  state: DocumentUpdateState,
  action: DocumentUpdateActions,
): DocumentUpdateState => {
  switch (action.type) {
    case 'setBank':
      return {
        ...state,
        data: { ...state.data, bankName: action.payload.name, bankIban: action.payload.iban },
        hasChanges: true,
      };
    case 'bankName':
      return { ...state, data: { ...state.data, bankName: action.payload }, hasChanges: true };
    case 'bankIban':
      return { ...state, data: { ...state.data, bankIban: action.payload }, hasChanges: true };
    case 'bankStartDate':
      return { ...state, data: { ...state.data, bankStartDate: action.payload }, hasChanges: true };
    case 'bankEndDate':
      return { ...state, data: { ...state.data, bankEndDate: action.payload }, hasChanges: true };
    case 'invoiceNumber':
      return { ...state, data: { ...state.data, invoiceNumber: action.payload }, hasChanges: true };
    case 'invoiceDate':
      return { ...state, data: { ...state.data, invoiceDate: action.payload }, hasChanges: true };
    case 'additionalInformation':
      return { ...state, data: { ...state.data, additionalInformation: action.payload }, hasChanges: true };
    case 'type':
      return {
        ...state,
        data: {
          ...state.data,
          type: action.payload,
          creditor:
            action.payload === InvoiceType.outgoing || action.payload === InvoiceType.bank ? '' : state.data.creditor,
          bankName: action.payload !== InvoiceType.bank ? '' : state.data.bankName,
          bankIban: action.payload !== InvoiceType.bank ? '' : state.data.bankIban,
          bankStartDate: action.payload !== InvoiceType.bank ? '' : state.data.bankStartDate,
          bankEndDate: action.payload !== InvoiceType.bank ? '' : state.data.bankEndDate,
          invoiceNumber: action.payload === InvoiceType.bank ? '' : state.data.invoiceNumber,
          invoiceDate: action.payload === InvoiceType.bank ? '' : state.data.invoiceDate,
        },
        hasChanges: true,
      };
    case 'creditor':
      return { ...state, data: { ...state.data, creditor: action.payload }, hasChanges: true };
    case 'debitor':
      return { ...state, data: { ...state.data, debitor: action.payload }, hasChanges: true };
    case 'originalName':
      return {
        ...state,
        data: { ...state.data, originalName: `${action.payload}.${state.fileExtension}` },
        fileName: action.payload,
        hasChanges: true,
      };
    case 'resetHasChanges':
      return { ...state, hasChanges: false };
    case 'clear':
      return { ...documentUpdateInitialState };
    case 'init':
      return {
        ...state,
        initialized: true,
        data: {
          creditor: action.payload.creditor || '',
          debitor: action.payload.debitor || '',
          invoiceDate: action.payload.invoiceDate || '',
          invoiceNumber: action.payload.invoiceNumber || '',
          type: action.payload.type || checkDocumentType(action.payload.fileType),
          bankName: action.payload.bankName,
          bankIban: action.payload.bankIban,
          bankStartDate: action.payload.bankStartDate,
          bankEndDate: action.payload.bankEndDate,
          originalName: action.payload.originalName,
          additionalInformation: action.payload.additionalInformation || '',
        },
        fileName: splitFileToNameAndExtension(action.payload.originalName).name,
        fileExtension: splitFileToNameAndExtension(action.payload.originalName).extension,
      };
    case 'updateInvoiceData':
      return {
        ...state,
        data: {
          ...state.data,
          type: action.payload.type || InvoiceType.incoming,
          creditor: action.payload.creditor || '',
          invoiceDate: isValidDate(action.payload.invoiceDate || '') ? action.payload.invoiceDate || '' : '',
          invoiceNumber: action.payload.invoiceNumber || '',
        },
        hasChanges: true,
      };
    default:
      throw new Error('Invalid type!');
  }
};
