import React from 'react';
import Header from '../../Components/Website/Header';
import InfoBoxes from '../../Components/Website/InfoBoxes';
import LanguageRow from '../../Components/Website/LanguageRow';
import Process from '../../Components/Website/Process';
import Presentation from '../../Components/Website/Presentation';
import Footer from '../../Components/Website/Footer';
import Customers from '../../Components/Website/Customers';
import PriceBox from '../../Components/Website/PriceBox';
import AppTeaser from '../../Components/Website/AppTeaser';
import Navigation from '../../Components/Website/Navigation';
import Config from '../../Library/Config';
import AiTeaser from '../../Components/Website/AiTeaser';

/**
 * Startpage()
 * @constructor
 */
export default function Startpage() {
  const url = `${window.location.protocol}//${window.location.host}`;

  if (url.indexOf(Config.loginDomain) > -1) {
    window.location.href = `${Config.domain}/login`;
    return null;
  } else {
    return (
      <div className={'flex flex-1 flex-col'}>
        <Navigation showPrice={true} showStartPage={false} showRegister={true} showLogin={true} />
        <Header />
        <InfoBoxes />
        <LanguageRow />
        <Process />
        <AiTeaser />
        <Presentation />
        <AppTeaser />
        <PriceBox />
        <Customers />
        <Footer />
      </div>
    );
  }
}
