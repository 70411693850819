import React from 'react';
import Container from '../Container/Container';
import { DocumentType } from '../../Library/Types/DocumentTypes';
import { useTranslation } from 'react-i18next';
import { useDispatchDocumentDownloadBase64 } from '../../Redux/Actions/DocumentActions';
import { documentGetTextFromExcelOrCsv, documentIsBankDocument } from '../../Library/Functions/DocumentFunctions';
import ActionButton from '../Container/ActionButton';
import classNames from 'classnames';
import { downloadBase64Pdf } from '../../Library/Functions/HelperFunctions';

interface Props {
  document: DocumentType;
  onStartLoading: () => void;
  onFinishedLoading: () => void;
  onTexts: (texts: string[]) => void;
}

/**
 * ExcelPreview()
 * @param props
 * @constructor
 */
export default function ExcelPreview(props: Props) {
  const { document, onTexts, onStartLoading, onFinishedLoading } = props;
  const [base64, setBase64] = React.useState<string>('');
  const { t } = useTranslation();
  const [downloaded, setDownloaded] = React.useState<boolean>(false);

  const downloadImage = useDispatchDocumentDownloadBase64(false);

  const downloadFile = React.useCallback(() => {
    downloadBase64Pdf(base64, document.originalName);
  }, [base64, document.originalName]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!base64 && !downloaded && documentIsBankDocument(document.fileType)) {
      onStartLoading();
      timer = setTimeout(() => {
        setDownloaded(true);

        downloadImage(document)
          .then((data) => {
            setBase64(data);
            documentGetTextFromExcelOrCsv(data).then((texts) => {
              onTexts(texts);
              return Promise.resolve();
            });
          })
          .catch((error) => {
            setBase64('');
            return Promise.resolve();
          })
          .finally(() => onFinishedLoading());
      }, 250);
    }

    return () => clearTimeout(timer);
  }, [base64, document, downloadImage, downloaded, onFinishedLoading, onStartLoading, onTexts]);

  if (document && documentIsBankDocument(document.fileType)) {
    return (
      <Container
        headline={t('document')}
        actionButtons={
          <ActionButton
            icon={classNames('fas fa-cloud-download')}
            className={classNames('hover:bg-blue-500 hover:text-white mr-0')}
            onPress={downloadFile}
          />
        }
      >
        <div className={'flex justify-center items-center py-20'}>
          <i className={'fat fa-file-xls text-gray-400'} style={{ fontSize: 200 }} />
        </div>
      </Container>
    );
  }
  return null;
}
