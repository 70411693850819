type Props = {
  visible: boolean;
  children: any;
};

/**
 * ShowHideContainer
 * @param props
 * @constructor
 */
export default function ShowHideContainer(props: Props) {
  const { visible, children } = props;

  if (visible) {
    return children;
  }
  return null;
}
