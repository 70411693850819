import React from 'react';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';
import { DocumentState } from '../../Library/Types/DocumentTypes';
import { Client } from '../../Library/Types/ClientTypes';
import Lodash from 'lodash';
import Table from '../Table';
import { clientBuildDisplayName } from '../../Library/Functions/ClientFunctions';
import classNames from 'classnames';
import { useNavigate } from 'react-router';

interface InState {
  client: Client;
  documentCount: number;
}

/**
 * AdminUploadedDocumentStateByClient()
 * @constructor
 */
export default function AdminUploadedDocumentStateByClient() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, clients } = useAppSelector((state) => state.auth);
  const { documents } = useAppSelector((state) => state.document);
  const [data, setData] = React.useState<InState[]>([]);

  React.useEffect(() => {
    if (documents && user.isCustomerAdmin) {
      const filteredDocuments = documents.filter((item) => item.state === DocumentState.uploaded);
      const clientIds = Lodash.union(filteredDocuments.map((item) => item.clientId));

      const result: InState[] = [];
      clientIds.forEach((item) => {
        const client = clients.find((param) => param.clientId === item);
        if (client) {
          const documentCount = filteredDocuments.filter((param) => param.clientId === item).length;
          result.push({ client, documentCount });
        }
      });

      setData(result.sort((a, b) => (a.documentCount > b.documentCount ? -1 : 1)));
    }
  }, [clients, documents, user.isCustomerAdmin]);

  const renderRow = () => {
    return data.map((item) => (
      <tr key={item.client.clientId} onClick={() => navigate(`/client/${item.client.clientId}`)}>
        <td>{clientBuildDisplayName(item.client)}</td>
        <td width={10} style={{ textAlign: 'center' }}>
          {item.documentCount}
        </td>
      </tr>
    ));
  };

  if (user.isCustomerAdmin && data && data.length > 0) {
    return (
      <Container
        headline={t('uploadedOpenByClient', {
          count: data.length,
          state: t(`modules.documents.statusText.${DocumentState.uploaded}`),
        })}
        closable
        identifier={'DashboardOpenDocumentsByUser'}
      >
        <div className={classNames('text-gray-500 text-sm', '-mt-4 mb-10 font-light')}>
          {t('uploadedOpenByClientDescription')}
        </div>
        <Table>
          <tbody>{renderRow()}</tbody>
        </Table>
      </Container>
    );
  }
  return null;
}
