import { InvoiceType } from '../Types/DocumentTypes';
import { DocumentUpdateState } from '../../Components/Document/DocumentUpdateReducer';

/**
 * validateDocumentInformation()
 * @param state
 */
const validateDocumentInformation = (state: DocumentUpdateState): boolean => {
  const { data } = state;

  if (data.type === InvoiceType.unknown) {
    return false;
  }

  if (data.type === InvoiceType.bank) {
    if (!data.bankEndDate || !data.bankStartDate || !data.bankName || !data.bankIban) {
      return false;
    }
    if (data.bankIban.length < 5 || data.bankIban.length > 34) {
      return false;
    }
  } else if (data.type === InvoiceType.incoming) {
    if (!data.invoiceNumber || !data.invoiceDate || !data.creditor) {
      return false;
    }
  } else if (data.type === InvoiceType.outgoing) {
    if (!data.invoiceNumber || !data.invoiceDate || !data.debitor) {
      return false;
    }
  }

  if (!data.originalName) {
    return false;
  }

  return true;
};

export default validateDocumentInformation;
